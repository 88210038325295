import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  cardHeaderTitle: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 700,
  },
  cardAction: {
    height: '100%',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'inherit',
  },
}))

const CardLink = ({ children, icon, path, title, action }) => {
  const classes = useStyles()
  if (typeof children === 'string') {
    children = <Typography>{children}</Typography>
  }

  return (
    <Card className={classes.card}>
      <CardActionArea
        className={classes.cardAction}
        component={props => <Link to={path} {...props} />}
        onClick={action}>
        {title && (
          <CardHeader
            avatar={
              icon ? <Avatar className={classes.avatar}>{icon}</Avatar> : null
            }
            title={title}
            classes={{
              title: classes.cardHeaderTitle,
            }}
          />
        )}
        <CardContent className={classes.cardContent}>{children}</CardContent>
      </CardActionArea>
    </Card>
  )
}

CardLink.propTypes = {
  action: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  classes: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default CardLink
