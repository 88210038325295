// Biblioteca externa
import { Map } from 'immutable'

// Imports relativos locais
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'

const RESOURCE_NAME = 'user'
const ENDPOINT = '/users'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingUserSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingUserSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_USER_SUCCESS = 'LOADING_USER_SUCCESS'
export function loadingUserSuccess(user) {
  return {
    type: LOADING_USER_SUCCESS,
    user,
  }
}

export const DESTROYING_USER_SUCCESS = 'DESTROYING_USER_SUCCESS'
export function destroyingUserSuccess(userId) {
  return {
    type: DESTROYING_USER_SUCCESS,
    userId,
  }
}

export function fetchMultipleUser(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchUser(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createUser(user) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', user).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateUser(id, user) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', user).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateUserPicture(id, user) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.callBinaryToPicture(`${ENDPOINT}/${id}`, 'PUT', user).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyUser(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const user = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_USER_SUCCESS: {
      const { user: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_USER_SUCCESS:
      return state.delete(action.userId.toString())

    default:
      return state
  }
}

export default user
