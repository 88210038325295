// Biblioteca externa
import axios from 'axios'

// Imports relativos locais
import config from '../config'

export async function call(endpoint, method = 'GET', data = null) {
  let headers = { ...{ method }, ...defaultHeaders() }
  if (data) {
    headers.data = JSON.stringify(data)
  }

  return axios(`${config.apiGateway.URL}${endpoint}`, headers).then(
    response => response.data
  )
}

export async function callBinary(endpoint, method = 'GET', data = null) {
  let headers = { ...{ method }, ...defaultHeaders() }
  if (data) {
    headers.data = data
  }

  return axios(`${config.apiGateway.URL}${endpoint}`, headers).then(
    response => response.data
  )
}

export async function callBinaryToPicture(endpoint, method = 'GET', data = null) {
  let headers = { ...{ method }, ...defaultHeaders() }

  return axios({
    url: `${config.apiGateway.URL}${endpoint}`,
    method,
    data, // `FormData` será enviado corretamente
    headers
  }).then(response => response.data)
}

export async function callWithResponse(endpoint, method = 'GET', data = null) {
  let headers = { ...{ method }, ...defaultHeaders() }
  if (data) {
    headers.data = JSON.stringify(data)
  }

  return axios(`${config.apiGateway.URL}${endpoint}`, headers).then(
    response => response
  )
}

export async function auth(email, password) {
  const payload = authHeaders(email, password)

  return axios(`${config.apiGateway.URL}/user_token`, payload).then(
    response => response.data
  )
}

export async function fetchMeta() {
  const { NODE_ENV } = process.env

  if (NODE_ENV === 'development') {
    const {
      REACT_APP_VERSION: version,
      REACT_APP_COMMIT_SHA: commit,
      REACT_APP_STAGE: stage,
    } = process.env
    return Promise.resolve({ version, commit, stage })
  }

  return axios('/meta.json').then(response => response.data)
}

const defaultHeaders = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ' ${getRawToken()}`,
  },
})

const authHeaders = (email, password) => ({
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  data: JSON.stringify({
    auth: {
      email,
      password,
    },
  }),
})

const getRawToken = () => {
  if (!localStorage.token) return null
  const token = JSON.parse(localStorage.token)
  return token.jwt
}
